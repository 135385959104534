import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {SelectedFileContext} from '../components/SelectedFileContext';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import ModelCard from "../components/ModelCard";
import {BASE_URL} from "../function/Config";
import {useForm} from 'react-hook-form';
import axios from 'axios';

const NewVideo = () => {

    const {selectFile,selectedFiles} = useContext(SelectedFileContext);
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const params = useParams();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const openModal = (model) => {
        setSelectedPlan(model);
        selectFile('model', model);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [uploadSuccess, setUploadSuccess] = useState(false);

    useEffect(() => {
        if (uploadSuccess) {
            closeModal();
        }
    }, [uploadSuccess]); // 将 uploadSuccess 添加到依赖项数组中
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        formData.append('language', data.language);
        formData.append('speakerName', data.speakerName);
        formData.append('id', params.userId);
        formData.append('modelName', selectedPlan.title);
    
        try {
            const token = sessionStorage.getItem('Token');
            setIsUploading(true);
            setUploadProgress(0);
            setUploadError(null);
    
            const response = await axios.post(`${BASE_URL}/videos/${params.userId}/upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
            });
    
            const feedback = response.data;
            console.log(feedback);
    
            if (feedback.message === "File uploaded successfully") {
                setUploadSuccess(true);
            } else {
                console.log(feedback.message);
                setUploadError(feedback.message || 'Upload failed. Please try again.');
            }
        } catch (error) {
            console.log('Upload error:', error);
            setUploadError('Upload failed. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };



    const models = [
        {id: 1, title: "Fast Talking-head Model", features: [
                "Our fastest talking-head model, able to produce SD avatar videos.",
                "Requires a portrait photo or a short talking-head video (more than 3 seconds) for input.",
                "Typically takes 4 minutes to generate a 2-minute lecture video."
            ]},
        {id: 2, title: "High-Resolution Talking-head Model", features: [
                "A more refined but slower avatar model, capable of producing HD avatar videos.",
                "Requires a portrait photo or a short talking-head video for input.",
                "Generally takes 10 minutes to create a 2-minute lecture video."
            ]},
        {id: 3, title: "Fine-Grain Gaussian Splatter Talking-head Model", features: [
                "Our premium model, though the slowest, specializes in customized avatars. It can produce HD avatar videos with natural lip movements and facial expressions.",
                "Requires a talking-head video of more than 30 seconds for training. Please ensure that your face is centered in the video, and that the video contains only your speaking voice.",
                "Involves training a model specifically for your face, which usually takes about 20 minutes.",
                "Typically requires 4 minutes to produce a 2-minute lecture video."
            ]}
    ];

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <div className="mx-auto mt-8">
                    {uploadSuccess && (
                        <div
                            id="toast-success"
                            style={{
                                width: '80%',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                            className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                            role="alert"
                        >
                            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ms-3 text-sm font-normal">Task created successfully.</div>
                            <button
                                onClick={() => setUploadSuccess(false)}
                                type="button"
                                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                data-dismiss-target="#toast-success"
                                aria-label="Close"
                            >
                                <span className="sr-only">Close</span>
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                        </div>
                    )}
                    <div className="flex flex-wrap justify-center items-center mt-20">
                        {models.map((model) => (
                            <div key={model.id} className="m-4">
                                <ModelCard
                                    key={model.id}
                                    title={model.title}
                                    features={model.features}
                                    onChoose={() => openModal(model)}
                                />
                            </div>
                        ))}
                        {isModalOpen && (
                            <div className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-md">
                                    {/* 进度条 */}
                                    {isUploading && (
                                        <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                                            <div
                                                className="bg-green-500 h-4 rounded-full"
                                                style={{ width: `${uploadProgress}%` }}
                                            ></div>
                                        </div>
                                    )}
    
                                    {/* 错误消息 */}
                                    {uploadError && <div className="text-red-500 mb-4">{uploadError}</div>}
    
                                    <form onSubmit={handleSubmit(onSubmit)} className="max-w-xl mx-auto p-8">
                                        <div className="mb-4">
                                            <h3 className="text-lg font-semibold">
                                                Selected Plan: {selectedPlan.title}
                                            </h3>
                                            <label className="block mb-2 mt-10 text-sm font-bold text-gray-700">
                                                Please select the language:
                                            </label>
                                            {errors.language && (
                                                <p className="text-red-600">{errors.language.message}</p>
                                            )}
                                            <select
                                                {...register('language', { required: 'This field is required' })}
                                                disabled={isUploading}
                                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Select Language</option>
                                                {['English'].map((lang) => (
                                                    <option key={lang} value={lang}>
                                                        {lang}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                                Please Enter the name of the task:
                                            </label>
                                            {errors.speakerName && (
                                                <p className="text-red-600">{errors.speakerName.message}</p>
                                            )}
                                            <input
                                                {...register('speakerName', { required: 'This field is required' })}
                                                disabled={isUploading}
                                                className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                type="text"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                                Please Upload the video:
                                            </label>
                                            {errors.file && <p className="text-red-600">{errors.file.message}</p>}
                                            <input
                                                {...register('file', { required: 'This field is required' })}
                                                type="file"
                                                disabled={isUploading}
                                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <button
                                                type="submit"
                                                disabled={isUploading}
                                                className={`w-full px-4 py-2 font-bold text-white rounded-full focus:outline-none focus:shadow-outline ${
                                                    isUploading
                                                        ? 'bg-gray-400 cursor-not-allowed'
                                                        : 'bg-blue-500 hover:bg-blue-700'
                                                }`}
                                            >
                                                {isUploading ? `Uploading ${uploadProgress}%` : 'Create'}
                                            </button>
                                        </div>
                                    </form>
                                    <button
                                        onClick={closeModal}
                                        disabled={isUploading}
                                        className={`px-4 py-2 text-white rounded hover:bg-red-700 ${
                                            isUploading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
                                        }`}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
    
};

export default NewVideo;