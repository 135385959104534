import React from "react";
import "../index.css"
import Home_Navbar from "../components/Home_Navbar";
import Homecontent from "../components/Homecontent";
import Footer from "../components/Footer";
export default function Homepage() {
    return (<main >
        <Home_Navbar />
        <Homecontent />
        {/*<Footer />*/}
    </main>)
}