import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { BASE_URL } from "../function/Config";
import axios from 'axios';

const Dropzone = ({ className, onResponse }) => {

    const [files, setFiles] = useState([]);
    const [rejected, setRejected] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0); // 上传进度
    const [isUploading, setIsUploading] = useState(false);   // 是否正在上传
    const [uploadError, setUploadError] = useState(null);    // 上传错误

    const params = useParams();
    const location = useLocation();

    const regex = /^\/([^\/]+)\/\d+$/;
    const match = location.pathname.match(regex);
    const pathSegment = match ? match[1] : '';

    // 上传文件的函数
    const uploadFiles = async (filesToUpload) => {
        if (!filesToUpload?.length) return;

        const formData = new FormData();
        filesToUpload.forEach(file => formData.append('file', file));

        const URL = `${BASE_URL}/${pathSegment}s/${params.userId}/upload`;
        const token = sessionStorage.getItem('Token');

        try {
            setIsUploading(true);
            setUploadProgress(0);
            setUploadError(null);

            const response = await axios.post(URL, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                onUploadProgress: progressEvent => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
            });

            const feedback = response.data;

            if (typeof onResponse === 'function') {
                onResponse(feedback);
            } else {
                console.error('onResponse is not a function');
            }

            // 上传完成后清空文件列表
            setFiles([]);
            setUploadProgress(0);

        } catch (error) {
            console.error('Upload error:', error);
            setUploadError('Upload failed. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    // 当文件被拖入或选择时调用
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles?.length) {
            setFiles(previousFiles => [
                ...previousFiles,
                ...acceptedFiles
            ]);
            uploadFiles(acceptedFiles); // 立即开始上传
        }

        if (rejectedFiles?.length) {
            setRejected(previousFiles => [...previousFiles, ...rejectedFiles]);
        }
    }, []);

    const mimeTypeMapping = {
        'audio': 'audio/*',
        'video': 'video/*',
        'slide': {
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx']
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: mimeTypeMapping[pathSegment] || {},
        onDrop
    });

    const removeFile = name => {
        setFiles(files => files.filter(file => file.name !== name));
    };

    const removeAll = () => {
        setFiles([]);
        setRejected([]);
    };

    const removeRejected = name => {
        setRejected(files => files.filter(({ file }) => file.name !== name));
    };

    return (
        <div>
            <div
                {...getRootProps({
                    className: className
                })}
            >
                <input {...getInputProps()} />
                <div className='flex flex-col items-center justify-center gap-4'>
                    <FontAwesomeIcon icon={faArrowUp} className='w-5 h-5 fill-current' />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p>Drag & drop files here, or click to select files</p>
                    )}
                </div>
            </div>

            {/* 上传进度条 */}
            {isUploading && (
                <>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                    <div className="mt-2 text-sm text-center text-gray-700">
                        Uploading... {uploadProgress}%
                    </div>
                </>
            )}
            {/* 上传错误信息 */}
            {uploadError && <p className="text-red-500 mt-2">{uploadError}</p>}

            {/* 预览和操作按钮 */}
            <section className='mt-10'>
                <div className='flex gap-4'>
                    <button
                        type='button'
                        onClick={removeAll}
                        className='mt-1 text-[12px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors'
                        disabled={isUploading}
                    >
                        Remove all files
                    </button>
                    {/* 已经自动上传，不再需要 Upload 按钮 */}
                </div>

                {/* Accepted files */}
                <h3 className='title text-lg font-semibold text-neutral-600 mt-10 border-b pb-3'>
                    Accepted Files
                </h3>
                <ul className='mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-10'>
                    {files.map((file, index) => (
                        <li key={index} className='relative h-16 rounded-md '>
                            <button
                                type='button'
                                className='w-7 h-7 border border-secondary-400 bg-secondary-400 rounded-full flex justify-center items-center absolute -top-3 -right-3 hover:bg-white transition-colors'
                                onClick={() => removeFile(file.name)}
                                disabled={isUploading}
                            >
                                <FontAwesomeIcon icon={faTimes} className='w-5 h-5 fill-white hover:fill-secondary-400 transition-colors' />
                            </button>
                            <p className='mt-2 text-neutral-500 text-[12px] font-medium'>
                                {file.name}
                            </p>
                        </li>
                    ))}
                </ul>

            </section>
        </div>
    );
};

export default Dropzone;
