import React from "react";
import "../index.css"
import Home_Navbar from "../components/Home_Navbar";
import Homecontent from "../components/Homecontent";
import Footer from "../components/Footer";
export default function About() {
    return (<main >
        <Home_Navbar />
        <div className="App bg-gray-100 min-h-screen flex items-center justify-center mt-20">
            <div className="container mx-auto p-4 max-w-2xl">
                <div className="bg-white shadow-2xl rounded-xl overflow-hidden">
                    <div className="px-8 py-10">
                        <h1 className="text-3xl font-bold text-indigo-700 text-center mb-8">UNSW Innovative Project</h1>

                        <p className="text-gray-800 text-lg mb-6">
                            Welcome to our innovative project at the University of New South Wales (UNSW), where a dedicated team of researchers is pioneering the future of education. We are a small yet passionate team, committed to enhancing learning experiences through the power of artificial intelligence.

                        </p>

                        <p className="text-gray-800 text-lg mb-6">
                            Our project centers around an AI-based Lecture Video Generation System, designed to revolutionize the way educational content is delivered. This system utilizes cutting-edge AI technologies to create dynamic, engaging, and informative lecture videos. By integrating advanced algorithms and creative approaches, we aim to provide an immersive learning environment that is accessible to students everywhere.

                        </p>

                        <p className="text-gray-800 text-lg mb-6">
                            Our team's expertise spans various fields, including computer science, education, and multimedia production, allowing us to craft a system that is not only technologically advanced but also pedagogically sound. At UNSW, we believe in pushing the boundaries of what's possible in education, and this project is a testament to that vision.
                        </p>
                    </div>
                    <div className="bg-indigo-500 px-8 py-4">
                        <p className="text-white text-sm italic text-center">
                            Together, we're building the future of educational technology.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/*<Footer />*/}
    </main>)
}