import { useHistory, useParams } from 'react-router-dom';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import React from "react";

export default function Dashboard() {
    const history = useHistory();
    const params = useParams();

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <div className="max-w-2xl mx-auto p-5 mt-10">
                    <div className="max-w-4xl mx-auto p-4">
                        {/* Added the instructional text above the video */}
                        <p className="text-center mb-4 text-lg">
                            This is the system's user instruction. If you encounter any problems, please send an email to <a href="mailto:wenbin.wang@unsw.edu.au" className="text-blue-600 underline">wenbin.wang@unsw.edu.au</a>.
                        </p>
                        <div className="relative w-full max-w-4xl mx-auto mt-4 overflow-hidden border border-gray-300 shadow-2xl rounded-3xl lg:dark:border-gray-700 pt-[56.25%]">
                            <iframe
                                title="YouTube video player"
                                src="https://www.youtube.com/embed/_qOm_Pkpd2k"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                className="absolute top-0 left-0 w-full h-full"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
