import React, {useContext} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faClipboard,faHeadphones,faVideo,faFilePowerpoint,faRobot } from '@fortawesome/free-solid-svg-icons';
import {SelectedFileContext} from "./SelectedFileContext";
import {BASE_URL} from "../function/Config";


function Sidebar() {
    const {selectedFiles} = useContext(SelectedFileContext);
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [uploadSuccess, setUploadSuccess] = React.useState(false);

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('video', selectedFiles.video.id);
            formData.append('audio', selectedFiles.audio.id);
            formData.append('slide', selectedFiles.slide.id);
            formData.append('speaker', selectedFiles.audio.filename);
            formData.append('videoName', selectedFiles.video.filename);
            formData.append('audioName', selectedFiles.audio.filename);
            formData.append('slideName', selectedFiles.slide.filename);

            // Todo 这里要把用了什么模型传过去
            formData.append('audioModel', selectedFiles.audio.model);
            formData.append('videoModel', selectedFiles.video.model);


            console.log(formData);
            const token = sessionStorage.getItem('Token');
            const response = await fetch(`${BASE_URL}/video-tasks/${params.userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                body: formData,
            });

            const feedback = await response.json();
            console.log(feedback);

            if (feedback.message === "Task created successfully") {
                setUploadSuccess(true)
            } else {
                console.log(feedback.message);
            }
        } catch (error) {
            console.log('Upload error:', error);
        }
    };

    return (
        <aside id="logo-sidebar"
               className="fixed top-0 left-0 w-64 h-screen pt-20 transition-transform transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
               aria-label="Sidebar">
            <div className="flex flex-col h-full px-2 pb-4 overflow-y-auto">
                <div className="mb-4">
                    <ul className="space-y-2 mt-3">
                        <li onClick={() => history.push(`/audio/${params.userId}`)}
                            className={` ${isActive('model') ? 'text-orange-500' : 'text-gray-600'} hover:text-blue-500 cursor-pointer`}>
                            Select or create a Voice
                        </li>
                        <li onClick={() => history.push(`/video/${params.userId}`)}
                            className={` ${isActive('video') ? 'text-orange-500' : 'text-gray-600'} hover:text-blue-500 cursor-pointer`}>
                            Select or create a Talking-head

                        </li>
                        <li onClick={() => history.push(`/slide/${params.userId}`)}
                            className={` ${isActive('slide') ? 'text-orange-500' : 'text-gray-600'} hover:text-blue-500 cursor-pointer`}>
                            Select or upload a Slides
                        </li>
                        <li onClick={() => history.push(`/history/${params.userId}`)}
                            className={` ${isActive('history') ? 'text-orange-500' : 'text-gray-600'} hover:text-blue-500 cursor-pointer`}>
                            History
                        </li>
                    </ul>
                </div>
                <div className="flex justify-center items-center mt-20">
                    <ul className="space-y-2">
                        <li className="text-center">
                            <FontAwesomeIcon icon={faClipboard}/>
                            <span className="ml-2 truncate">New Task</span>
                        </li>
                    </ul>
                </div>
                <div className="mt-6">
                    <ul>
                        <li className="mt-2 ml-4">
                            <FontAwesomeIcon icon={faHeadphones}/>
                            <span className="ml-2 truncate"> {selectedFiles.audio ? selectedFiles.audio.filename : ''}</span>
                        </li>
                        <li className="mt-2 ml-4">
                            <FontAwesomeIcon icon={faVideo}/>
                            <span className="ml-2 truncate"> {selectedFiles.video ? selectedFiles.video.filename : ''}</span>
                        </li>
                        <li className="mt-2 ml-4">
                            <FontAwesomeIcon icon={faFilePowerpoint}/>
                            <span className="ml-2 truncate"> {selectedFiles.slide ? selectedFiles.slide.filename : ''}</span>
                        </li>
                        <div className="mt-4 flex items-center justify-center">
                            <button
                                className={`px-3 py-1.5 text-sm font-medium rounded-md shadow transition duration-300 ease-in-out ${
                                    selectedFiles.video && selectedFiles.audio && selectedFiles.slide
                                        ? 'bg-blue-500 hover:bg-blue-600 text-white hover:shadow-md'
                                        : 'bg-gray-400 text-gray-200 hover:bg-gray-500'
                                }`}
                                onClick={handleSubmit}
                                disabled={!selectedFiles.video || !selectedFiles.audio || !selectedFiles.slide}
                            >
                                Submit
                            </button>
                        </div>
                    </ul>
                    {uploadSuccess && (
                        <div id="toast-success"
                             className="fixed top-25 right-1 z-50 flex w-full mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                             role="alert">
                            <div
                                className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ms-3 text-sm font-normal">Task create successfully.</div>
                            <button onClick={() => setUploadSuccess(false)} type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#toast-success" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </button>
                        </div>)}
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;
