import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Home_Navbar from "../components/Home_Navbar";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ModelCard from "../components/ModelCard";
import { BASE_URL } from "../function/Config";

export default function NewAudio() {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const params = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [originalFile, setOriginalFile] = useState('');
    const [filelists, setFilelists] = useState([]);

    const [uploadSuccess, setUploadSuccess] = useState(false);

    const openModal = (model) => {
        setSelectedPlan(model);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (uploadSuccess) {
            closeModal();
        }
    }, [uploadSuccess]);

    // 新增的 useEffect，用于在 3 秒后自动关闭弹出窗口
    useEffect(() => {
        if (uploadSuccess) {
            const timer = setTimeout(() => {
                setUploadSuccess(false);
            }, 3000);
            // 清除定时器以防止内存泄漏
            return () => clearTimeout(timer);
        }
    }, [uploadSuccess]);

    useEffect(() => {
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/audios/${params.userId}/audio`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
                setFilelists(data);
                console.log(filelists);
            })
    }, [params.userId]);

    const onSubmit = async (data) => {
        console.log("Submitting form with data:", data);

        const payload = {
            fileId: originalFile,
            language: data.language,
            speakerName: data.speakerName,
            id: params.userId,
            modelName: selectedPlan.title,
        };

        console.log("Payload prepared:", payload);

        try {
            const token = sessionStorage.getItem('Token');
            console.log("Sending request to server...");
            const response = await fetch(`${BASE_URL}/audio-tasks/${params.userId}/new_task`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(payload),
            });

            const feedback = await response.json();
            console.log("Response from server:", feedback);

            if (feedback.message === "Task created successfully") {
                setUploadSuccess(true);
                closeModal();
            } else {
                console.log("Failed to upload:", feedback.message);
            }
        } catch (error) {
            console.log('Upload error:', error);
        }
    };

    const models = [
        {
            id: 1, title: "Instant", features: [
                "Our fastest voice cloning model. Suitable for standard accents.",
                "You need to provide 5 seconds of reference speech audio.",
                "No waiting time for the model to learn your voice characteristics.",
                "Can produce speech audio with acceptable quality."]
        },
        {
            id: 3, title: "Diffusion-based", features: [
                "Our best but slowest voice cloning model. Suitable for speakers with accents.",
                "You need to provide more than 15 seconds of reference speech audio.",
                "You need to wait for more than 10 minutes for the model to complete learning your voice characteristics.",
                "Can produce speech audio with the best quality."
            ]
        }
    ];

    const handleChange = (event) => {
        const selected = event.target.value;
        setOriginalFile(selected);
    };

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <div className="mx-auto mt-8">
                    {uploadSuccess && (
                        <div id="toast-success"
                            style={{
                                width: '80%',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            }}
                            className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                            role="alert">
                            <div
                                className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ms-3 text-sm font-normal"> Task create successfully.</div>
                            <button onClick={() => setUploadSuccess(false)} type="button"
                                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                data-dismiss-target="#toast-success" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                        </div>)}
                </div>
                <div className="flex justify-center">
                    <p className="text-4xl font-bold text-center mt-20">Voice Cloning Model</p>
                </div>
                <div className="flex justify-center items-center mt-10">

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 mx-auto">
                        {models.map(model => (
                            <ModelCard
                                key={model.id}
                                title={model.title}
                                features={model.features}
                                onChoose={() => openModal(model)}
                            />
                        ))}
                        {isModalOpen && (
                            <div
                                className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white p-4 rounded-lg shadow-xl">
                                    <form onSubmit={handleSubmit(onSubmit)}
                                        className="max-w-xl mx-auto p-8">
                                        <div className="mb-4">
                                            <h3 className="text-lg font-semibold">Selected
                                                Plan: {selectedPlan.title}</h3>
                                            <label
                                                className="block mb-2 mt-10 text-sm font-bold text-gray-700">
                                                Please select the language:
                                            </label>
                                            {errors.language &&
                                                <p className="text-red-600">{errors.language.message}</p>}
                                            <select {...register("language", { required: "This field is required" })}
                                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                                                <option value="">Select Language</option>
                                                {['English'].map((lang) => (
                                                    <option key={lang} value={lang}>{lang}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                                Please Enter the name of the speaker:
                                            </label>
                                            {errors.speakerName &&
                                                <p className="text-red-600">{errors.speakerName.message}</p>}
                                            <input {...register("speakerName", { required: "This field is required" })}
                                                className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                type="text" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                                Please select audio file:
                                            </label>
                                            {errors.file &&
                                                <p className="text-red-600">{errors.file.message}</p>}

                                            <div>
                                                <select
                                                    value={originalFile}
                                                    onChange={handleChange}
                                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                >
                                                    {filelists.length > 0 ? (
                                                        <>
                                                            <option value="">Select a file</option>
                                                            {filelists.map((file, index) => (
                                                                <option key={index} value={file.id}>
                                                                    {file.filename}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <option value="">None</option>
                                                    )}
                                                </select>

                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <button type="submit"
                                                className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline">
                                                Create
                                            </button>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <button onClick={closeModal}
                                                className=" px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">
                                                Close
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
