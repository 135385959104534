import {useHistory, useParams} from 'react-router-dom';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import React, {useEffect, useState} from "react";
import {BASE_URL} from "../function/Config";
import SystemError from "../components/SystemError";

export default function History() {
    const history = useHistory();
    const params = useParams();
    const [Loading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentView, setCurrentView] = useState('audio');
    const [audioTasks, setAudioTasks] = useState([]);
    const [avatarTasks, setAvatarTasks] = useState([]);
    const [videoTasks, setVideoTasks] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const AudioTableHeader = () => (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            {/* 音频任务的表头 */}
            <th scope="col" className="px-6 py-3">Speaker</th>
            <th scope="col" className="px-6 py-3">Language</th>
            <th scope="col" className="px-6 py-3">Model</th>
            <th scope="col" className="px-6 py-3">Status</th>
            <th scope="col" className="px-6 py-3">Created At</th>
        </tr>
        </thead>
    );

    const VideoTableHeader = () => (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            {/* 视频任务的表头 */}
            <th scope="col" className="px-6 py-3">Speaker</th>
            <th scope="col" className="px-6 py-3">Slides</th>
            <th scope="col" className="px-6 py-3">Video</th>
            <th scope="col" className="px-6 py-3">Status</th>
            <th scope="col" className="px-6 py-3">Download</th>
            <th scope="col" className="px-6 py-3">Created At</th>


        </tr>
        </thead>
    );


    const fetchTasks = () => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        const url = `${BASE_URL}/${currentView}-tasks/${params.userId}/all`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (currentView === 'audio') {
                    setAudioTasks(data);
                } else if (currentView === 'avatar') {
                    setAvatarTasks(data);
                    console.log(data);
                } else {
                    setVideoTasks(data);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    };


    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString('en-CA');
    };

    useEffect(() => {
        fetchTasks();
    }, [currentView]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const changeView = (view) => {
        setCurrentView(view);
        setDropdownOpen(false);
    };

    return (
        <section>
            <Home_Navbar/>
            <Sidebar/>
            {showModal && <SystemError onClose={() => setShowModal(false)}/>}
            <div className="p-4 sm:ml-64">
                <div className=" flex flex-wrap justify-center items-center  mt-20">
                    <div className="relative overflow-x-auto shadow-md w-full max-w-4xl mx-auto">
                        <div className="flex justify-between mb-4 text-center">
                            <button
                                className={`px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out 
            ${currentView === 'audio' ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                                onClick={() => setCurrentView('audio')}
                            >
                                Audio Tasks History
                            </button>
                            <button
                                className={`px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out 
            ${currentView === 'avatar' ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                                onClick={() => setCurrentView('avatar')}
                            >
                                Avatar Tasks History
                            </button>
                            <button
                                className={`px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out 
            ${currentView === 'video' ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                                onClick={() => setCurrentView('video')}
                            >
                                Video Tasks History
                            </button>
                        </div>

                        <table className="w-full text-sm text-center text-gray-500">
                            {currentView !== 'video' ? <AudioTableHeader /> : <VideoTableHeader />}
                            <tbody>
                            {(currentView !== 'video' ? (currentView === 'audio' ? audioTasks : avatarTasks) : videoTasks).map(task => (
                                <tr key={task.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    {currentView !== 'video' ? (
                                        <>
                                            <td className="px-6 py-4">{task.filename}</td>
                                            <td className="px-6 py-4">{task.language}</td>
                                            <td className="px-6 py-4">{task.model}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td className="px-6 py-4">{task.speaker}</td>
                                            <td className="px-6 py-4">{task.slideName}</td>
                                            <td className="px-6 py-4">{task.videoName}</td>
                                            <td className="px-6 py-4">
                                                {task.filepath ? (
                                                    <a
                                                        href={`${BASE_URL}/video-tasks/${task.id}`}
                                                        className="text-blue-500 hover:text-blue-600"
                                                        download
                                                    >
                                                        Download
                                                    </a>
                                                ) : (
                                                    <span className="text-gray-400">Download not available</span>
                                                )}
                                            </td>

                                        </>
                                    )}
                                    <td className="px-6 py-4">
                    <span className={`font-medium ${task.status === 'done' ? 'text-green-600' : 'text-red-600'}`}>
                        {task.status}
                    </span>
                                    </td>
                                    <td className="px-6 py-4">{formatDate(task.created_at)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}