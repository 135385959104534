import React from "react";
import "../index.css"
import Home_Navbar from "../components/Home_Navbar";
import Homecontent from "../components/Homecontent";
import Footer from "../components/Footer";
export default function Contact() {
    return (<main >
        <Home_Navbar />
        <div className="App bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="container mx-auto p-4 max-w-2xl">
                <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="px-6 py-8">
                        <div className="mb-8 text-center">
                            <h1 className="text-3xl font-bold text-indigo-700">UNSW Innovative Project</h1>
                        </div>

                        <p className="text-gray-700 text-base mb-4">
                            For any technical support inquiries or assistance with our AI-based Lecture Video Generation System, "AutoLV," please get in touch with:
                        </p>
                        <div className="bg-indigo-100 p-4 rounded-lg mb-6">
                            <p className="font-semibold">Wenbin Wang</p>
                            <p>Email: <a href="mailto:wenbin.wang@unsw.edu.au" className="text-indigo-600 hover:text-indigo-800">wenbin.wang@unsw.edu.au</a></p>
                        </div>

                        <p className="text-gray-700 text-base mb-4">
                            For business collaboration opportunities or partnerships, please get in touch with:
                        </p>
                        <div className="bg-indigo-100 p-4 rounded-lg mb-6">
                            <p className="font-semibold">Professor Sanjay Jha</p>
                            <p>Email: <a href="mailto:sanjay.jha@unsw.edu.au" className="text-indigo-600 hover:text-indigo-800">sanjay.jha@unsw.edu.au</a></p>
                        </div>

                        <p className="text-gray-700 text-base">
                            Our team at the University of New South Wales is dedicated to providing prompt and detailed responses to all inquiries. We value your interest and look forward to potential collaborations that can advance the scope and impact of educational technology.
                        </p>
                    </div>
                    <div className="bg-indigo-100 px-6 py-4">
                        <p className="text-indigo-600 text-sm italic">
                            Together, we're building the future of educational technology.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/*<Footer />*/}
    </main>)
}