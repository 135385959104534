function ModelCard({ title, features, onChoose }) {
    // 函数用于突出关键信息
    const highlightFeature = (feature) => {
        return feature
            .replace(/(fastest|better|best)/, '<strong class="text-green-500">$1</strong>')
            .replace(/(more than \d+ seconds|around \d+ minutes| seconds)/, '<strong class="text-blue-600">$1</strong>')
            .replace(/(no waiting time|acceptable quality|best quality)/, '<strong class="text-purple-600">$1</strong>')
            .replace(/(standard accents|speakers with accents)/, '<strong class="text-red-500">$1</strong>');
    };

    return (
        <div className="max-w-sm p-4 bg-white border-2 border-gray-300 rounded-lg shadow-xl">
            <h5 className="text-2xl font-semibold text-gray-700 mb-4 text-center">{title}</h5>
            <ul className="list-disc list-inside space-y-2 mb-4">
                {features.map((feature, index) => (
                    <li key={index} className="text-gray-600" dangerouslySetInnerHTML={{ __html: highlightFeature(feature) }} />
                ))}
            </ul>
            <button
                onClick={onChoose}
                className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-300"
            >
                Choose Plan
            </button>
        </div>
    );
}


export default ModelCard