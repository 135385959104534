import {useForm} from "react-hook-form";
import React, {useState} from "react";
import Error from "../components/Error";
import {useHistory} from "react-router-dom";
import Home_Navbar from "../components/Home_Navbar";
import {BASE_URL} from "../function/Config";

// Todo Email already exists Errror

export default function Signup() {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const history = useHistory();
    const [error, setError] = useState('');
    const Submit = async (data) => {
        try {
            const response = await fetch(`${BASE_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const feedback = await response.json();
            sessionStorage.setItem('Token', feedback.access_token);
            if (feedback.user_id !== undefined) {
                history.push(`/dashboard/${feedback.user_id}`);
            }

        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const handleCloseError = () => {
        setError('');
    };

    return (
        <>
            <Home_Navbar />
            <section className="bg-gray-200 dark:bg-gray-900 min-h-screen flex items-center justify-center ">
                <div className="flex flex-row">
                    <div className="flex-1 flex items-center justify-end bg-gray-800 text-white roundedbilibi ">
                        <div className="flex flex-col">
                            <h1 className="text-3xl flex justify-center items-center text-center font-bold mb-6">Start Building with Your Free Plan</h1>
                            <ul className="text-lg px-16 py-8">
                                <li className="py-2">AI-Powered Video Generator</li>
                                <li className="py-2">High-Quality Spoken Audio</li>
                                <li className="py-2">Voice Cloning Technology</li>
                                <li className="py-2">Virtual Presenter Feature</li>
                                <li className="py-2">Enhanced Content Delivery</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col bg-white text-gray-900  ">
                        {error && <Error content={error} onClose={handleCloseError}/>}
                        <div className="rounded-lg shadow-lg p-6 w-full max-w-md">
                            <div className="space-y-4">
                                <form onSubmit={handleSubmit((data) => {
                                    Submit(data)
                                })} className="space-y-6" action="#">
                                    <div>
                                        <label htmlFor="name"
                                               className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                        <input type="name" {...register("name", {required: "This is required."})} id="name"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                                        <p className="text-xs text-red-600">{errors.name?.message}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="email"
                                               className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                        <input type="email" {...register("email", {required: "This is required."})}
                                               id="email"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                                        <p className="text-xs text-red-600">{errors.email?.message}</p>
                                    </div>
                                    <div>
                                        <label htmlFor="password"
                                               className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                        <input type="password" {...register("password", {
                                            required: "This is required.",
                                            minLength: {value: 8, message: "Min Length is 8"}
                                        })} id="password"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                                        <p className="text-xs text-red-600">{errors.password?.message}</p>
                                    </div>

                                    <button type="submit"
                                            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-150 ease-in-out">Create
                                        an account
                                    </button>
                                    <p className="text-sm text-gray-600">
                                        Already have an account? <a href="/signin"
                                                                    className="text-primary-600 hover:underline">Sign in
                                        here</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}